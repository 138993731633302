// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

let $ = require('jquery');
require("jquery-ui");
require("jquery-ujs");

let jQueryBridget = require('jquery-bridget');
let Isotope = require('isotope-layout');
// make Isotope a jQuery plugin
jQueryBridget('isotope', Isotope, $);

require('packs/slick.min.js');
require('packs/core_functions');
require('packs/loader');
require('packs/all.js');
require('bootstrap');
