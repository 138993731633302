let ready = function () {

  // burger
  $('.js--burger').on('click', function () {
    $('body').toggleClass('body--burger');
    return false;
  });
  $('.header__menu a').on('click', function () {
    $('body').removeClass('body--burger');
  });


  // скролл
  $('.js--scroll').on('click', function () {
    $('html, body').animate({
      scrollTop: $($(this).attr('href')).offset().top
    }, 400);
    return false;
  });

  // филтры курсов
  $grid = $('.courses__items').isotope({
    itemSelector: '.courses__item-wrap',
    layoutMode: 'fitRows'
  });
  $('.courses__filter').on('click', function () {
    $('.courses__filter').removeClass('active');
    $(this).addClass('active');
    $grid.isotope({filter: $(this).data('sort')});
  });


  // gallery
  $(".gallery__items").slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          dots: false,
          variableWidth: false,
          adaptiveHeight: true
        }
      }
    ]
  });

  $('.gallery__links a').on('click', function (e) {
    e.preventDefault();
    let $t = $(this);
    $t.parent().find('.active').removeClass('active');
    $t.addClass('active');

    $('.gallery__items').addClass('d-none');
    $('.gallery__items[data-gal-name="' + $t.attr('data-gal-name') + '"]').removeClass('d-none');
    $('.gallery__items').slick('refresh');
  });


  //.gallery

  $('.reviews__carousel').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1000,
        centerMode: true,
        settings: {
          dots: false,
          variableWidth: false,
          adaptiveHeight: true
        }
      }
    ]
  });

  $('.js--city').on('click', function () {
    $('.header__city-choose').toggleClass('header__city-choose--active');
    return false;
  });

  // смена города
  (function () {
    let init = function () {
      let city = localStorage.getItem('city');
      //if (city === null) {
        city = 'mazatlan'; // это будет город по умолчанию
        localStorage.setItem('city', city);
      //}
      $('.js-hidden-city').val(city);
      $('select option[value=' + city + ']').attr('selected', 'selected');
      $('.header__city-links .header__city-link[data-city=' + city + ']').addClass('header__city-link--active');

      $('.header__city-links .header__city-link').on('click', function (e) {
        e.preventDefault();
        let _city = $(this).data('city'),
          _lscity = localStorage.getItem('city');

        if (_city === _lscity) {
          return false;
        }

        $('.js-city-hide').addClass('d-none');
        $('.js-city-show-' + _city).removeClass('d-none');
        localStorage.setItem('city', _city);
        $('.js-hidden-city').val(_city);

        $('.header__city-links .header__city-link').removeClass('header__city-link--active');
        $('.header__city-links .header__city-link[data-city=' + _city + ']').addClass('header__city-link--active');
        $('.header__city-choose').toggleClass('header__city-choose--active');

        changeCity();
      });
    };
    init();

    let changeCity = function () {
      let _city = localStorage.getItem('city');
      $('.js-city-hide').addClass('d-none');
      $('.js-city-show-' + _city).removeClass('d-none');

      let $s = $('.js-city-select'), $selected = $s.find('option[selected]');

      if (_city === $selected.attr('value')) {
        return false;
      }

      if (typeof window.myMapSetCity === 'function') {
        window.myMapSetCity(_city);
      }

      $selected.removeAttr('selected');
      $s.find('option[value=' + _city + ']').attr('selected', 'selected');
      $s.trigger('change');
    };

    $('.js-city-select').on('change', function () {
      localStorage.setItem('city', this.value);
      $('.js-hidden-city').val(this.value);
      changeCity();
    });

    changeCity();
  })();
  //.смена города

  // карусель достижение
  let achievementsActive = 1;
  $('.js--achievements-left').on('click', function () {
    if (achievementsActive <= 1) {
      achievementsActive = $('.achievements__item').length;
    } else {
      achievementsActive--;
    }
    ShowAchievements(achievementsActive);
    return false;
  });
  $('.js--achievements-right').on('click', function () {
    if (achievementsActive >= $('.achievements__item').length) {
      achievementsActive = 1;
    } else {
      achievementsActive++;
    }
    ShowAchievements(achievementsActive);
    return false;
  });

  function ShowAchievements(active) {
    let items = $('.achievements__item');
    let length = items.length;
    for (let i = 0; i < length; i++) {
      if (i + active <= length) {
        items.eq(i).attr('class', 'achievements__item achievements__item--' + (i + active));
      } else {
        items.eq(i).attr('class', 'achievements__item achievements__item--' + (i + active - length));
      }
    }
    $('.achievements__num span').html(active);

    $('.modal__desc--achievements *[data-achievement]').addClass('d-none');
    $('.modal__desc--achievements *[data-achievement="' + active + '"]').removeClass('d-none');
  }

  // карусель учителя
  // let teacherActive = $('.teachers__item').length;
  // $('.js--teachers-left').on('click', function () {
  //   if (teacherActive <= 1) {
  //     teacherActive = $('.teachers__item').length;
  //   } else {
  //     teacherActive--;
  //   }
  //   ShowTeachers(teacherActive);
  //   return false;
  // });
  // $('.js--teachers-right').on('click', function () {
  //   if (teacherActive >= $('.teachers__item').length) {
  //     teacherActive = 1;
  //   } else {
  //     teacherActive++;
  //   }
  //   ShowTeachers(teacherActive);
  //   return false;
  // });

  // teachers
  $('.teachers__images').slick({
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }).on('afterChange', function (event, slick, currentSlide, nextSlide) {
    let index = parseInt($('.teachers__images .slick-current .teachers__img').attr('data-id-teacher')) + 1
    let total = $('.teachers__item').length;
    if (window.innerWidth < 1000) {
      index = index - 2;
    }
    if (index >= total) {
      index = index - total;
    }
    $('.teachers__num span').html(index + 1);
    $('.teachers__item').removeClass('teachers__item--active');
    $('.teachers__item').eq(index).addClass('teachers__item--active');

    $('.modal--teachers *[data-teacher]').addClass('d-none');
    $('.modal--teachers *[data-teacher="' + (index + 1) + '"]').removeClass('d-none');
  })


  $('.js--teachers-left').on('click', function () {
    $('.teachers__images').slick('slickPrev');
    return false;
  });
  $('.js--teachers-right').on('click', function () {
    $('.teachers__images').slick('slickNext');
    return false;
  });

  function ShowTeachers(active) {
    let images = $('.teachers__img');
    let items = $('.teachers__item');
    let length = items.length;
    $('.teachers__images').stop().animate({
      marginRight: -131 - 305 * (length - active)
    }, 500, function () {

    });
    $('.teachers__num span').html(active);
    items.removeClass('teachers__item--active').eq(active - 1).addClass('teachers__item--active');
    images.removeClass('teachers__img--gradient teachers__img--prev teachers__img--active');
    images.eq(active - 1).addClass('teachers__img--active');
    images.eq(active - 2).addClass('teachers__img--prev');
    images.eq(active - 3).addClass('teachers__img--gradient');

    $('.modal--teachers *[data-teacher]').addClass('d-none');
    $('.modal--teachers *[data-teacher="' + active + '"]').removeClass('d-none');
  }

  if (window.innerWidth < 1000) {
    let items = $('.teachers__item');
    items.removeClass('teachers__item--active').eq(0).addClass('teachers__item--active');
  }

  // изменение якоря
  // $(window).on('hashchange', function () {
  //   // курсы
  //   let coursesmatches = location.hash.match(/^[#|](\d+-\d+.+)$/);
  //   if (coursesmatches !== null) {
  //     let a = decodeURIComponent(coursesmatches[1]);
  //     let $t = $('.courses__filter[href*="#' + a + '"]');
  //
  //     $t.closest('.courses__filters').find('.active').removeClass('active');
  //     $t.addClass('active');
  //
  //     $('.courses__item-wrap').addClass('d-none');
  //     $('.courses__item-wrap[data-for*="' + a + '"]').removeClass('d-none');
  //
  //     $('html, body').animate({
  //       scrollTop: $('.courses').offset().top
  //     }, 100);
  //   }
  //   //.курсы
  // }).trigger('hashchange');
  //.изменение якоря

  // message
  window.showBootstrapMessage = function (params) {
    params = $.extend({
      title: '',
      message: ''
    }, params);

    let $v_modal = $('.modal:visible'),
      $m_modal = $('#modal-message'), $title = $m_modal.find('.modal-title'), $message = $m_modal.find('.modal-body');

    $v_modal.modal('hide');
    $('.modal-backdrop').remove();

    $title.html(params.title);
    $message.html('<p>' + params.message + '</p>');

    $m_modal.modal('show');

    if (typeof params['afterHide'] === 'function') {
      $m_modal.on('hidden.bs.modal', function () {
        $m_modal.off('hidden.bs.modal');
        params.afterHide.call();
      });
    }
  };

  // modal
  window.showBootstrapModal = function (selector) {
    $('.modal:visible').modal('hide');
    $('.modal-backdrop').remove();
    $(selector).modal('show');
  };

  // inputmask
  $('input[data-mask]').each(function () {
    var $t = $(this), mask = $t.data('mask');
    if (typeof $t.attr('disabled') === 'undefined') {
      $t.inputmask('remove').inputmask('' + mask);
    }
  });
};

$(document).ready(ready);
$(document).on('page:load', ready);

